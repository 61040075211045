import React from "react";

export default function Admission(){
    return [
        <div>
            <button className="button-1"><a href="https://sjbhs.edu.in/Admission_to_LKG.php">LKG</a></button>
            <button className="button-0"><a href="https://sjbhs.edu.in/applying_for_std11.php">ISC</a></button>
        </div>
    ]
}
